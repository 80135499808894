import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { load } from 'react-cookies'
export const BASE_URL = process.env.REACT_APP_BASE_URL

export const userApiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const userInfo = load("userInfo")
      const token = userInfo?.token
      headers.set("Authorization", `Token ${token}`)
      return headers
    },
  }),
  tagTypes: [
    'UserLogin',
    "GetUser",
    "GetMembers",
    "GetArea",
    'GetAdvancedEnterprise',
    'GetMainprojectLevelType',
    'GetEvaluateplan',
    'GetSuccessfulEntrepreneur',
    'GetSuccessfulEntrepreneurDetail',
    'GetExcellentProjectmanager',
    'GetExcellentProjectmanagerDetail',
    "GetAdvancedEnterpriseAdwardMinistry",
    "GetQualityControl",
    "GetQualityControlDetail",
  ],
  endpoints: builder => ({
    userLogin: builder.mutation({
      query: (data) => {
        return {
          url: "/api/token-auth/",
          method: "POST",
          body: data,
        }
      },
      providesTags: ["UserLogin"],
      invalidatesTags: [
        'GetMembers',
        'GetUser',
        'GetArea',
        'GetAdvancedEnterprise',
        'GetMainprojectLevelType',
        'GetEvaluateplan',
        'GetSuccessfulEntrepreneur',
        'GetSuccessfulEntrepreneurDetail',
        'ExcellentProject',
        'ExcellentProjectDetail',
        "GetAdvancedEnterpriseAdwardMinistry"
      ],
    }),
    getUser: builder.query({
      query: () => {
        return ({ url: `/api/members/token/` })
      },
      providesTags: ["GetUser"],
    }),

    // advanced enterprice start
    getAdvancedEnterprise: builder.query({
      query: () => {
        return ({ url: `/api/advancedenterprise/` })
      },
      providesTags: ["GetAdvancedEnterprise"],
    }),
    getAdvancedEnterpriseDetail: builder.query({
      query: ({ id }) => {
        return ({ url: `/api/advancedenterprise/${id}/` })
      },
      providesTags: (result, error, page) => {
        const { id } = page
        return [{ type: "GetAdvancedEnterpriseDetail", id }]
      }
    }),
    addAdvancedEnterprise: builder.mutation({
      query: ({ formData }) => {
        return ({
          url: `/api/advancedenterprise/`,
          method: 'POST',
          body: formData,
          FormData: true
        })
      },
      invalidatesTags: (result, error, arg) => {
        const rst = [
          { type: "GetAdvancedEnterprise" },
        ]
        return rst
      }
    }),
    updateAdvancedEnterprise: builder.mutation({
      query: ({ payload, id }) => {
        return ({
          url: `/api/advancedenterprise/${id}/`,
          method: 'PUT',
          body: payload,
        })
      },
      invalidatesTags: (result, error, arg) => {
        const { id } = arg
        const rst = [
          { type: "GetAdvancedEnterpriseDetail", id },
          { type: "GetAdvancedEnterprise" },
        ]
        return rst
      }
    }),
    deleteAdvancedEnterprise: builder.mutation({
      query: ({ id }) => {
        return ({
          url: `/api/advancedenterprise/${id}/`,
          method: 'DELETE'
        })
      },
      invalidatesTags: (result, error, arg) => {
        const rst = [
          { type: "GetAdvancedEnterprise" },
        ]
        return rst
      }
    }),
    updateAdvancedEnterpriseAttached: builder.mutation({
      query: ({ payload, id }) => {
        return ({
          url: `/api/advancedenterprise/${id}/update_attached/`,
          method: 'PUT',
          body: payload,
          // body: formData,
          // FormData: true
        })
      },
    }),
    updateAdvancedEnterpriseAdward: builder.mutation({
      query: ({ payload, id }) => {
        return ({
          url: `/api/advancedenterprise/${id}/update_award_attached/`,
          method: 'PUT',
          body: payload,
        })
      },
    }),
    // advanced enterprice end
    // successful entrepreneur start
    getSuccessfulEntrepreneur: builder.query({
      query: () => {
        return ({ url: `/api/successfulentrepreneur/` })
      },
      providesTags: ["GetSuccessfulEntrepreneur"],
    }),
    getSuccessfulEntrepreneurDetail: builder.query({
      query: ({ id }) => {
        return ({ url: `/api/successfulentrepreneur/${id}/` })
      },
      providesTags: (result, error, page) => {
        const { id } = page
        return [{ type: "GetSuccessfulEntrepreneurDetail", id }]
      }
    }),
    addSuccessfulEntrepreneur: builder.mutation({
      query: ({ formData }) => {
        return ({
          url: `/api/successfulentrepreneur/`,
          method: 'POST',
          body: formData,
          FormData: true
        })
      },
      invalidatesTags: (result, error, arg) => {
        const rst = [
          { type: "GetSuccessfulEntrepreneur" },
        ]
        return rst
      }
    }),
    updateSuccessfulEntrepreneur: builder.mutation({
      query: ({ payload, id }) => {
        return ({
          url: `/api/successfulentrepreneur/${id}/`,
          method: 'PUT',
          body: payload,
        })
      },
      invalidatesTags: (result, error, arg) => {
        const { id } = result
        const rst = [
          { type: "GetSuccessfulEntrepreneurDetail", id },
          { type: "GetSuccessfulEntrepreneur" },
        ]
        return rst
      }
    }),
    deleteSuccessfulEntrepreneur: builder.mutation({
      query: ({ id }) => {
        return ({
          url: `/api/successfulentrepreneur/${id}/`,
          method: 'DELETE'
        })
      },
      invalidatesTags: (result, error, arg) => {
        const rst = [
          { type: "GetSuccessfulEntrepreneur" },
        ]
        return rst
      }
    }),
    updateSuccessfulEntrepreneurAttached: builder.mutation({
      query: ({ payload, id }) => {
        return ({
          url: `/api/successfulentrepreneur/${id}/update_attached/`,
          method: 'PUT',
          body: payload,
        })
      },
    }),
    updateSuccessfulEntrepreneurAdward: builder.mutation({
      query: ({ payload, id }) => {
        return ({
          url: `/api/successfulentrepreneur/${id}/update_award_attached/`,
          method: 'PUT',
          body: payload,
        })
      },
    }),
    // successful entrepreneur end
    // quality control start
    getQualityControl: builder.query({
      query: () => {
        return ({ url: `/api/qualitycontrol/` })
      },
      providesTags: ["GetQualityControl"],
    }),
    getQualityControlDetail: builder.query({
      query: ({ id }) => {
        return ({ url: `/api/qualitycontrol/${id}/` })
      },
      providesTags: (result, error, page) => {
        const { id } = page
        return [{ type: "GetQualityControlDetail", id }]
      }
    }),
    addQualityControl: builder.mutation({
      query: ({ formData }) => {
        return ({
          url: `/api/qualitycontrol/`,
          method: 'POST',
          body: formData,
          FormData: true
        })
      },
      invalidatesTags: (result, error, arg) => {
        const rst = [
          { type: "GetQualityControl" },
        ]
        return rst
      }
    }),
    updateQualityControl: builder.mutation({
      query: ({ payload, id }) => {
        return ({
          url: `/api/qualitycontrol/${id}/`,
          method: 'PUT',
          body: payload,
        })
      },
      invalidatesTags: (result, error, arg) => {
        const { id } = result
        const rst = [
          { type: "GetQualityControlDetail", id },
          { type: "GetQualityControl" },
        ]
        return rst
      }
    }),
    deleteQualityControl: builder.mutation({
      query: ({ id }) => {
        return ({
          url: `/api/qualitycontrol/${id}/`,
          method: 'DELETE'
        })
      },
      invalidatesTags: (result, error, arg) => {
        const rst = [
          { type: "GetQualityControl" },
        ]
        return rst
      }
    }),
    updateQualityControlAttached: builder.mutation({
      query: ({ payload, id, type }) => {
        const url_extra = type === 1 ? "update_attached1" : "update_attached"
        return ({
          url: `/api/qualitycontrol/${id}/${url_extra}/`,
          method: 'PUT',
          body: payload,
        })
      },
    }),
    updateQualityControlAdward: builder.mutation({
      query: ({ payload, id }) => {
        return ({
          url: `/api/qualitycontrol/${id}/update_award_attached/`,
          method: 'PUT',
          body: payload,
        })
      },
    }),
    // quality control end
    // excellent projectmanager start
    getExcellentProjectmanager: builder.query({
      query: () => {
        return ({ url: `/api/excellentprojectmanager/` })
      },
      providesTags: ["GetExcellentProjectmanager"],
    }),
    getExcellentProjectmanagerDetail: builder.query({
      query: ({ id }) => {
        return ({ url: `/api/excellentprojectmanager/${id}/` })
      },
      providesTags: (result, error, page) => {
        const { id } = page
        return [{ type: "GetExcellentProjectmanagerDetail", id }]
      }
    }),
    addExcellentProjectmanager: builder.mutation({
      query: ({ formData }) => {
        return ({
          url: `/api/excellentprojectmanager/`,
          method: 'POST',
          body: formData,
          FormData: true
        })
      },
      invalidatesTags: (result, error, arg) => {
        const rst = [
          { type: "GetExcellentProjectmanager" },
        ]
        return rst
      }
    }),
    updateExcellentProjectmanager: builder.mutation({
      query: ({ payload, id }) => {
        return ({
          url: `/api/excellentprojectmanager/${id}/`,
          method: 'PUT',
          body: payload,
        })
      },
      invalidatesTags: (result, error, arg) => {
        const { id } = result
        const rst = [
          { type: "GetExcellentProjectmanagerDetail", id },
          { type: "GetExcellentProjectmanager" },
        ]
        return rst
      }
    }),
    deleteExcellentProjectmanager: builder.mutation({
      query: ({ id }) => {
        return ({
          url: `/api/excellentprojectmanager/${id}/`,
          method: 'DELETE'
        })
      },
      invalidatesTags: (result, error, arg) => {
        const rst = [
          { type: "GetExcellentProjectmanager" },
        ]
        return rst
      }
    }),
    updateExcellentProjectmanagerAttached: builder.mutation({
      query: ({ payload, id }) => {
        return ({
          url: `/api/excellentprojectmanager/${id}/update_attached/`,
          method: 'PUT',
          body: payload,
        })
      },
    }),
    updateExcellentProjectmanagerAdward: builder.mutation({
      query: ({ payload, id }) => {
        return ({
          url: `/api/excellentprojectmanager/${id}/update_award_attached/`,
          method: 'PUT',
          body: payload,
        })
      },
    }),
    // excellent projectmanager end
    getEvaluateplan: builder.query({
      query: ({ type }) => {
        return ({ url: `/api/evaluateplan/?type=${type}` })
      },
      providesTags: ["GetEvaluateplan"],
    }),
    getArea: builder.query({
      query: () => {
        return ({ url: `/api/members/get_area/` })
      },
      providesTags: ["GetArea"],
    }),
    getMember: builder.query({
      query: ({ page, companyName, area }) => `/api/members/?page=${page}&companyName=${companyName}&area=${area}`,
      providesTags: (result, error, page) => {
        return result ?
          [
            ...result.results.map(({ id }) => ({ type: 'GetMembers', id })),
            { type: 'GetMembers', id: 'PARTIAL-LIST' }
          ] :
          [{ type: "GetMembers", id: 'PARTIAL-LIST' }]
      }
    }),
    getMainprojectLevelType: builder.query({
      query: () => {
        return ({ url: `/api/mainprojectleveltype/` })
      },
      providesTags: ["GetMainprojectLevelType"]
    }),
    changePassword: builder.mutation({
      query: ({ value: payload }) => {
        return ({
          url: `/api/members/change_password/`,
          method: 'POST',
          body: payload
        })
      },
    }),
    updateMember: builder.mutation({
      query: ({ values: payload }) => {
        const id = payload?.id
        return ({
          url: `/api/members/${id}/`,
          method: 'PATCH',
          body: { ...payload }
        })
      },
      invalidatesTags: (result, error, arg) => {
        // const { memberID: id } = arg
        const rst = [
          { type: "GetUser" },
          { type: "GetMembers", id: 'PARTIAL-LIST' }
        ]
        return rst
      }
    })
  })
})
export const {
  useUserLoginMutation,
  useGetUserQuery,
  useGetMemberQuery,
  useUpdateMemberMutation,
  useGetAreaQuery,
  useChangePasswordMutation,
  useGetMainprojectLevelTypeQuery,
  useGetEvaluateplanQuery,

  useGetAdvancedEnterpriseQuery,
  useGetAdvancedEnterpriseDetailQuery,
  useAddAdvancedEnterpriseMutation,
  useUpdateAdvancedEnterpriseMutation,
  useDeleteAdvancedEnterpriseMutation,
  useUpdateAdvancedEnterpriseAttachedMutation,
  useUpdateAdvancedEnterpriseAdwardMutation,

  useGetQualityControlQuery,
  useGetQualityControlDetailQuery,
  useAddQualityControlMutation,
  useUpdateQualityControlMutation,
  useDeleteQualityControlMutation,
  useUpdateQualityControlAttachedMutation,
  useUpdateQualityControlAdwardMutation,

  useGetSuccessfulEntrepreneurQuery,
  useGetSuccessfulEntrepreneurDetailQuery,
  useAddSuccessfulEntrepreneurMutation,
  useUpdateSuccessfulEntrepreneurMutation,
  useDeleteSuccessfulEntrepreneurMutation,
  useUpdateSuccessfulEntrepreneurAttachedMutation,
  useUpdateSuccessfulEntrepreneurAdwardMutation,

  useGetExcellentProjectmanagerQuery,
  useGetExcellentProjectmanagerDetailQuery,
  useAddExcellentProjectmanagerMutation,
  useUpdateExcellentProjectmanagerMutation,
  useDeleteExcellentProjectmanagerMutation,
  useUpdateExcellentProjectmanagerAttachedMutation,
  useUpdateExcellentProjectmanagerAdwardMutation,
} = userApiSlice
