import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './container/Home';
import AddressBook from './container/AddressBook';
import Main from './container/Main';
import RequireUser from './container/RequireUser';
import Login from './container/Login';
import ChangePassword from './container/ChangePassword';
import { ConfigProvider } from 'antd';
import AdvancedEnterprise from './container/advancedenterprise/list'
import AdvancedEnterpriseAdd from './container/advancedenterprise/add';
import SuccessfulEntrepreneur from './container/successfulentrepreneur/list';
import SuccessfulEntrepreneurAdd from './container/successfulentrepreneur/add';
import ExcellentProjectmanager from './container/excellentprojectmanager/list';
import ExcellentProjectmanagerAdd from './container/excellentprojectmanager/add';
import zhCN from 'antd/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import Cert from './container/Cert';
import QualityControl from './container/qualitycontrol/list';
import QualityControlAdd from './container/qualitycontrol/add';

const App = () => {
  const antedTheme = {
    token: {
      colorPrimary: '#447e9b',
      colorBgHead: '#417690'
    }
  }
  return (
    <ConfigProvider locale={zhCN} theme={antedTheme}>
      <Routes>
        <Route element={<RequireUser />}>
          <Route path="/" element={<Main />}>
            <Route exact path='' element={<Home />}></Route>
            <Route path="members" element={<AddressBook />}></Route>
            <Route path="cert" element={<Cert />}></Route>
            <Route path="changepass" element={<ChangePassword />}></Route>
            <Route path="advancedenterprise" element={<AdvancedEnterprise />}></Route>
            <Route path="advancedenterprise/add" element={<AdvancedEnterpriseAdd />}></Route>
            <Route path="advancedenterprise/change/:id" element={<AdvancedEnterpriseAdd />}></Route>
            <Route path="successfulentrepreneur" element={<SuccessfulEntrepreneur />}></Route>
            <Route path="successfulentrepreneur/add" element={<SuccessfulEntrepreneurAdd />}></Route>
            <Route path="successfulentrepreneur/change/:id" element={<SuccessfulEntrepreneurAdd />}></Route>
            <Route path="excellentprojectmanager" element={<ExcellentProjectmanager />}></Route>
            <Route path="excellentprojectmanager/add" element={<ExcellentProjectmanagerAdd />}></Route>
            <Route path="excellentprojectmanager/change/:id" element={<ExcellentProjectmanagerAdd />}></Route>
            <Route path="qualitycontrol" element={<QualityControl />}></Route>
            <Route path="qualitycontrol/add" element={<QualityControlAdd />}></Route>
            <Route path="qualitycontrol/change/:id" element={<QualityControlAdd />}></Route>
          </Route>
        </Route>
        <Route element={<Login />} path='login' />
      </Routes>
    </ConfigProvider>
  );
};
export default App;
