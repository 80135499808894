import { Table, Space, Button, message, Tag, Popconfirm } from "antd";
import React from "react";
import { BASE_URL, useAddQualityControlMutation, useDeleteQualityControlMutation, useGetQualityControlQuery, useGetEvaluateplanQuery } from "../../features/api/userAPI";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { FormOutlined, ReloadOutlined, } from "@ant-design/icons";
import { load } from "react-cookies";
import ContentWrap from "../../components/ContentHead";

const QualityControl = () => {
  const [deleteQualityControl, { isLoading: deleteIsLoading }] = useDeleteQualityControlMutation()
  const columns = [
    { title: "申请年份", dataIndex: "year", key: "year" },
    { title: "企业名称", dataIndex: "name", key: "name" },
    { title: "课题名称", dataIndex: "subject_name", key: "subject_name" },
    { title: "小组类型", dataIndex: "group_type_display", key: "group_type_display" },
    { title: "小组联系人", dataIndex: "group_contract", key: "group_contract" },
    {
      title: "结束日期", key: "end_date", render: (_, { year }) => {
        return <div>{rst?.end_date}</div>
      }
    },
    {
      title: "状态", key: "status", render: (_, { status, status_display, year }) => {
        if (rst?.year !== year) {
          return <Tag>已结束</Tag>
        } else if (!isEventStart) {
          return <Tag>{rst?.status}</Tag>
        } else {
          if (status === 0) {
            return <Tag color="processing">{status_display}</Tag>
          } else if (status === 1) {
            return <Tag color="success">{status_display}</Tag>
          } else {
            return <Tag>{status_display}</Tag>
          }
        }
      }
    },
    {
      title: "操作", key: "action", render: (_, { name, status, id, year }) => {
        if (status === 0 && isEventStart && rst?.year === year) {
          return (
            <Space>
              <Link to={`change/${id}`}>
                修改
              </Link>
              <Popconfirm
                title="删除后无法恢复"
                description="您确定要删除数据吗? "
                onConfirm={() => {
                  deleteQualityControl({ id })
                }}
              >
                <Link >
                  删除
                </Link>
              </Popconfirm>
            </Space >
          )
        } else if (status === 1) {
          return (
            <Space>
              <Link onClick={async () => {
                const downloadUrl = `${BASE_URL}/api/qualitycontrol/${id}/pdf/`
                const userInfo = load("userInfo")
                const token = userInfo?.token
                const result = await fetch(downloadUrl, {
                  headers: {
                    Authorization: `Token ${token}`,
                  },
                });
                const blob = await result.blob();
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.download = `质量管理小组活动成果申报表-${name}.pdf`
                link.href = href
                link.click()
                URL.revokeObjectURL(href);
              }}
                target="_self"
                rel="noreferrer">
                下载申报表
              </Link>

            </Space>
          )

        }
      }
    },
  ]
  const [addQualityControl, { isLoading: addIsLoading }] = useAddQualityControlMutation()

  const { data: evaluatePlanData, isLoading: evaluatePlanDataLoading, isError, error, refetch } = useGetEvaluateplanQuery({ type: 4 })
  
  const rst = evaluatePlanData?.results[0]
  const { data, isLoading } = useGetQualityControlQuery()
  const errorStatus = error?.status
  const navigate = useNavigate()
  const isEventStart = rst?.status === "进行中.."
  const showRefresh = rst?.status !== "进行中.."
  if (isError && errorStatus === 401) {
    return (
      <Navigate to='/login' replace />
    )
  } else {
    const dataSource = data?.results
    return (
      <ContentWrap breadItems={[{ title: "质量管理" }, { title: "质量管理小组活动(QC)" }]}>
        <Space size={"middle"} className="mb-4">
          {isEventStart &&
            <Button onClick={() => {
              const formData = new FormData()
              formData.append("year", rst.year)
              addQualityControl({ formData }).then((rst) => {
                const _id = rst?.data?.id
                if (_id) {
                  navigate(`/qualitycontrol/change/${_id}`)
                } else {
                  message.error("添加失败")
                }
              })
            }} type="primary" icon={<FormOutlined />} size="middle" loading={addIsLoading || deleteIsLoading}>
              新增申请
            </Button>}
          {showRefresh &&
            <Button loading={evaluatePlanDataLoading} onClick={() => refetch()} icon={<ReloadOutlined />} size="middle">
              未开始
            </Button>
          }
        </Space>
        <Table
          loading={isLoading}
          rowKey="id"
          dataSource={dataSource}
          columns={columns}
        />
      </ContentWrap>
    )
  }
}

export default QualityControl
