import { Input, Row, Col } from "antd";
import { useState} from "react";

const GroupList = (props) => {
    const { value, onChange } = props;

    const splitSymbol = "、"
    const initialInputs = value ? value.split(splitSymbol) : Array(10).fill("");
    const [inputs, setInputs] = useState(initialInputs);


    const onTextChange = (index, val) => {
        const newInputs = [...inputs];
        newInputs[index] = val; // 更新对应索引的值

        // 过滤掉空白字符串
        const filteredInputs = newInputs.filter(item => item.trim() !== "");
        setInputs(filteredInputs); // 更新状态
        onChange(filteredInputs.join(splitSymbol)); // 将数组拼接成字符串并传递给父组件
    };

    const inputCount = 10;
    const inputElements = Array.from({ length: inputCount }, (_, index) => (
        <Col span={4} key={index} className="mb-3">
            <Input
                type="text1"
                className="ant-form-text"
                value={inputs[index] || ""} // 确保输入框有值
                onChange={(e) => onTextChange(index, e.target.value)} // 传递索引和新值
            />
        </Col>
    ));

    return <Row gutter={[16, 0]}>{inputElements}</Row>;
};

export default GroupList;